<template>
  <div class="" style="margin-top:200rem;">
    <p class="error">{{ error }}</p><!--错误信息-->

    <p class="decode-result">扫描结果: <b>{{ result }}</b></p><!--扫描结果-->
    <!-- <p @click="returnHone()" style="margin-top:100rem;height:100rem;">回首页</p> -->
    <StreamBarcodeReader @decode ="onDecode" @loaded ="onLoaded" @init="onInit"></StreamBarcodeReader>
  </div>
</template>

<script>
import { StreamBarcodeReader } from 'vue-barcode-reader'

export default {
  props: {

  },
  components: {
    StreamBarcodeReader
  },
  data () {
    return {
      result: '',
      error: ''
    }
  },
  computed: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    // returnHone () {
    //   this.$router.push({ name: 'home', params: { barcode: 1111 } })
    // },
    onDecode (result) {
      alert(result)
      this.result = result
      this.$router.push({ name: 'home', params: { barcode: result } })
    },
    onLoaded () {},
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: 您需要授予相机访问权限'
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: 这个设备上没有摄像头'
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: 所需的安全上下文(HTTPS、本地主机)'
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: 相机被占用'
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: 安装摄像头不合适'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: 此浏览器不支持流API'
        }
      }
    }
  }
}
</script>

<style scoped lang="less">

</style>
